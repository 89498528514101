import React from 'react';
import PropTypes from 'prop-types';
import CountUp from 'react-countup';
import { connect } from 'react-redux';

import { createStructuredSelector } from 'reselect';
import * as selectors from 'redux/selectors';
import stateImage from '../../img/large-ca-code-logo.png';
import './LargeBanner.css';

const LargeBanner = ({repoData, summaryData}) => (
  <div className={"container"} style={{maxWidth: "100%", padding: "0"}}>

    <div className={"banner col-12"}>
      <div className={"banner-content"}>

        <div className={"header-container col-7"}>
          <h1 className={"header-title"}>CodeCA OpenSource</h1>
          <h4 className={"header-subtitle"}>
Improving access to the State of California’s
            <br />
            {' '}
custom-developed software.
          </h4>
          <a href={"/Explore"} className={"btn btn-primary btn-hover"} role={"button"} style={{fontSize: "2rem"}}>Explore Code</a>
        </div>

        <div className={"counters-container col-5"}>
          <div className={"counters-content"}>
            <img src={stateImage} alt={"State of California"} style={{width: "70%"}} />
            <div className={"card-row"} style={{justifyContent: 'space-evenly'}}>      
              <div>
                <div className={"stat-counter text-accent9"}>
                  <CountUp end={repoData.totalCount ? repoData.totalCount : 0} />
                </div>
                <p className={"text-center"}>Repositories</p>
              </div>
              <div>
                <div className={"stat-counter text-accent9"}>
                  <CountUp end={summaryData.orgNames ? summaryData.orgNames.length : 0} />
                </div>
                <p className={"text-center"}>Organizations</p>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
);

LargeBanner.propTypes = {
  repoData: PropTypes.object.isRequired,
  summaryData: PropTypes.object.isRequired,
}

export const mapStateToProps = createStructuredSelector({
  summaryData: selectors.getSummaryData(),
  repoData: selectors.getRepoData(),
});

const usingRedux = connect(mapStateToProps, null)(LargeBanner);

export default usingRedux;

