import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FieldCheckboxes, Form } from 'state-template';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import * as actions from 'redux/actions';
import * as selectors from 'redux/selectors';

import Collapse from 'react-bootstrap/Collapse';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import Pagination from 'react-js-pagination';
import RepoCard from '../RepoCard/RepoCard';
import './ExploreCodePage.css';

const ExploreCodePage = ({summaryData, getSummaryData, repoData, getRepoData}) => {
  // List of repositories coming from API fetch
  const [repoList, setRepoList] = useState([]);

  // Used to dynamically build filter checkbox groups
  const [languageCheckboxes, setLanguageCheckboxes] = useState([]);
  const [agencyCheckboxes, setAgencyCheckboxes] = useState([]);
  const [scoreCheckboxes] = useState([
    { value: 'Unverified', label: 'Unverified' },
    { value: 'NoScanning', label: 'No Scanning' },
    { value: 'Alerts', label: 'Alerts' },
    { value: 'AllGood', label: 'All Good' },
  ]);

  // Set state for Open/Close of Filters
  const [openLanguageFilter, setOpenLanguageFilter] = useState(true);
  const [openScoringFilter, setOpenScoringFilter] = useState(true);
  const [openAgencyFilter, setOpenAgencyFilter] = useState(true);

  // Tracks which filter checkboxes are currently checked
  const [filterByLanguage, setFilterByLanguage] = useState([]);
  const [filterByAgency, setFilterByAgency] = useState([]);
  const [filterByScore, setFilterByScore] = useState([]);
  const [filterByString, setFilterByString] = useState('');

  // State for Pagination component
  const [paginationConfig, setPaginationConfig] = useState({
    activePage: 1,
    itemsCountPerPage: 10,
    totalItemsCount: 0,
    pageRangeDisplayed: 5,
  });

  // Use SummaryData from API call to dynamically populate language filter checkbox group with unique values
  const populateLanguageCheckboxes = (orgList) => {
    const languagesUsedArr = [];
    const languageCheckboxValues = [];

    orgList.forEach((language) => {
      languagesUsedArr.push(language);
    });

    languagesUsedArr.sort();
    languagesUsedArr.forEach((language) => {
      if(language){
        languageCheckboxValues.push({ value: language, label: language });
      }
    });

    setLanguageCheckboxes(languageCheckboxValues);
  };

  // Use SummaryData from API call to dynamically populate agency filter checkbox group with unique values
  const populateAgencyCheckboxes = (orgList) => {
    const agencyUsedArr = [];
    const agencyCheckboxValues = [];
    orgList.forEach((orgName) => {
      agencyUsedArr.push(orgName);
    });

    agencyUsedArr.sort();
    agencyUsedArr.forEach((agency) => {
      agencyCheckboxValues.push({ value: agency, label: agency });
    });

    setAgencyCheckboxes(agencyCheckboxValues);
  };

  // Close Language and Scoring filters if smaller screen
  useEffect(() => {    
    const width = window.innerWidth
    || document.documentElement.clientWidth
    || document.body.clientWidth;
    
    if(width < 768){
      setOpenLanguageFilter(false);
      setOpenScoringFilter(false);
    }
  },[])


  // Fires if any listed useState value changes
  useEffect(() => {
    const { CODECA_API } = window.config;

    // Fired if filters, search bar, or pagination controls are changed
    const updateOrgData = async () => {
      const baseUrl = `${CODECA_API}/Github?`;
      let queryString = '';

      // If language filter array has value
      // URI encode values for use in queryString
      if (filterByLanguage.length > 0) {
        const encodedLangArr = filterByLanguage.map(
          (lang) => `Languages=${encodeURIComponent(lang)}&`,
        );
        const encodedLangString = encodedLangArr.join('');
        queryString = queryString.concat(encodedLangString);
      }
      // If agency filter array has value
      // URI encode values for use in queryString
      if (filterByAgency.length > 0) {
        const encodedAgencyArr = filterByAgency.map(
          (agency) => `OrganizationNames=${encodeURIComponent(agency)}&`,
        );
        const encodedAgencyString = encodedAgencyArr.join('');
        queryString = queryString.concat(encodedAgencyString);
      }
      // If score filter array has value
      // URI encode values for use in queryString
      if (filterByScore.length > 0) {
        const encodedScoreArr = filterByScore.map(
          (agency) => `Scores=${encodeURIComponent(agency)}&`,
        );
        const encodedScoreString = encodedScoreArr.join('');
        queryString = queryString.concat(encodedScoreString);
      }
      // If search bar has value
      // URI encode value for use in queryString
      if (filterByString.length > 0) {
        const encodedString = `FilterString=${encodeURIComponent(
          filterByString,
        )}&`;
        queryString = queryString.concat(encodedString);
      }

      // Set active page number in queryString
      const pageNumber = `PageNumber=${paginationConfig.activePage}&`;
      queryString = queryString.concat(pageNumber);
      const itemsPerPage = `PageSize=${paginationConfig.itemsCountPerPage}`;
      queryString = queryString.concat(itemsPerPage);
      const finalUrl = baseUrl.concat(queryString);

      // getRepoData(finalUrl);
      
      const response = await fetch(finalUrl);
      const data = await response.json();

      setRepoList(data.repositories);
      if (paginationConfig.totalItemsCount !== data.totalCount) {
        setPaginationConfig({
          ...paginationConfig,
          totalItemsCount: data.totalCount,
        });
      }
    };
    updateOrgData();
  }, [
    filterByLanguage,
    filterByAgency,
    filterByScore,
    filterByString,
    paginationConfig,
  ]);

  // Fires only on initial page load
  useEffect(() => {
    getSummaryData();   
    getRepoData(); 
    // getOrgData();
  }, [getSummaryData, getRepoData]);

  // Populate filter checkboxes with summaryData
  useEffect(() => {
    if(summaryData.orgNames){
      populateAgencyCheckboxes(summaryData.orgNames);
      populateLanguageCheckboxes(summaryData.languagesUsed);
    }
  }, [summaryData])

  useEffect(() => {
    if(repoData.repositories){
      setRepoList(repoData.repositories);
    }
  }, [repoData]);

  // Fired on search input change
  const getSearchValue = (searchString) => {
    setFilterByString(searchString.target.value);
    setPaginationConfig({ ...paginationConfig, activePage: 1 });
  };

  // Fired when language filter is toggled, sets filterByLanguage to reflect currently checked checkboxes
  // Example: ['JavaScript', 'Python', 'C++']
  const getLanguageCheckboxValue = (languageArray) => {
    setFilterByLanguage(languageArray);
    setPaginationConfig({ ...paginationConfig, activePage: 1 });
  };

  // Fired when agency filter is toggled, sets filterByAgency to reflect currently checked checkboxes
  // Example: ['18F', 'State of California']
  const getAgencyCheckboxValue = (agencyArray) => {
    setFilterByAgency(agencyArray);
    setPaginationConfig({ ...paginationConfig, activePage: 1 });
  };

  // Fired when score filter is toggled, sets filterByScore to reflect currently checked checkboxes
  // Example: ['AllGood', 'Unverified']
  const getScoreCheckboxValue = (scoreArray) => {
    setFilterByScore(scoreArray);
    setPaginationConfig({ ...paginationConfig, activePage: 1 });
  };

  // Builds content for when no results are found
  // Can probably be refactored
  const noResultsContent = () => {
    let content;
    if (!document.getElementById('input_search_orgs')) {
      content = (
        <div>
          <h2>No results found</h2>
          <h3>
            Please try again or contact us at
            {' '}
            <a href={'mailto: codecasupport@state.ca.gov'}>
              codecasupport@state.ca.gov
            </a>
          </h3>
        </div>
      );
    } else {
      const searchTerms = document.getElementById('input_search_orgs').value;
      content = (
        <div>
          <h2>{`No results found for '${searchTerms}'`}</h2>
          <h3>Please try a different search or adjust filters.</h3>
        </div>
      );
    }
    return content;
  };

  // Fired on Pagination onChange event
  // Sets activePage with event target value
  const handlePagination = (e) => {
    setPaginationConfig({ ...paginationConfig, activePage: e });
  };

  // Destructuring for use in Pagination component
  const {
    activePage,
    totalItemsCount,
    itemsCountPerPage,
    pageRangeDisplayed,
  } = paginationConfig;

  // Calculate total pages
  const totalPages = Math.ceil(totalItemsCount / itemsCountPerPage);

  const setPerPage = (e) => {
    setPaginationConfig({
      ...paginationConfig,
      itemsCountPerPage: parseInt(e.target.innerText, 10),
    });
  };

  // Render page
  return (
    <div className={'main-content'}>
      <div className={"title-banner"}>
        <h1 style={{fontSize: '2rem'}}>Explore Code</h1>
      </div>
      <div className={"add-margin container"}>

        <div className={'header-search'}>
          <input
            className={'form-control col-md-12'}
            type={'search'}
            placeholder={'Search repositories'}
            onChange={getSearchValue}
            id={'input_search_orgs'}
            aria-label={'Search organizations'}
          />
        </div>
        
        <div id={'content_container'}>
          <div id={'filters_container'}>
            <h2 className={'text-dark py-2 pr-4 m-0'} style={{ minWidth: '18%' }}>
              <strong className={'text-secondary'}>{totalItemsCount}</strong>
              {' Repositories'}
            </h2>
            <div className={'card'}>
              <Button
                variant={'primary'}
                onClick={() => setOpenAgencyFilter(!openAgencyFilter)}
                aria-controls={'filter_agency'}
                aria-expanded={openAgencyFilter}
                block
              >
              Organizations
              </Button>
              <Collapse in={openAgencyFilter}>
                <div id={'filter_agency'}>
                  <Form
                    enableReinitialize
                    form={'form_agency'}
                    className={'form-checkboxes'}
                    id={'form_agency_checkboxes'}
                    onSubmit={'Submitted'}
                  >
                    <FieldCheckboxes
                      inline
                      name={'agenciesCheckboxes'}
                      label={''}
                      onChange={getAgencyCheckboxValue}
                      options={agencyCheckboxes}
                      aria-label={'Filter by agency checkboxes'}
                    />
                  </Form>
                </div>
              </Collapse>
            </div>
            <div className={'card'}>
              <Button
                variant={'primary'}
                onClick={() => setOpenLanguageFilter(!openLanguageFilter)}
                aria-controls={'filter_language'}
                aria-expanded={openLanguageFilter}
                block
              >
              Languages
              </Button>
              <Collapse in={openLanguageFilter}>
                <div id={'filter_language'}>
                  <Form
                    enableReinitialize
                    form={'form_language'}
                    className={'form-checkboxes'}
                    id={'form_language_checkboxes'}
                    onSubmit={'Submitted'}
                  >
                    <FieldCheckboxes
                      inline
                      name={'languagesCheckboxes'}
                      label={''}
                      onChange={getLanguageCheckboxValue}
                      options={languageCheckboxes}
                      aria-label={'Filter by language checkboxes'}
                    />
                  </Form>
                </div>
              </Collapse>
            </div>
            <div className={'card'}>
              <Button
                variant={'primary'}
                onClick={() => setOpenScoringFilter(!openScoringFilter)}
                aria-controls={'filter_scoring'}
                aria-expanded={openScoringFilter}
                block
              >
              Scoring
              </Button>
              <Collapse in={openScoringFilter}>
                <div id={'filter_scoring'}>
                  <Form
                    variant={'primary'}
                    enableReinitialize
                    form={'form_scoring'}
                    className={'form-checkboxes'}
                    id={'form_score_checkboxes'}
                    onSubmit={'Submitted'}
                    block
                  >
                    <FieldCheckboxes
                      inline
                      name={'scoringCheckboxes'}
                      label={''}
                      onChange={getScoreCheckboxValue}
                      options={scoreCheckboxes}
                      aria-label={'Filter by score checkboxes'}
                    />
                  </Form>
                </div>
              </Collapse>
            </div>
          </div>
          <div id={'repo_list_container'}>
            <div className={"repo-list-header"}>

              <Dropdown
                style={{
                alignSelf: 'flex-end',
                marginRight: 'auto'
              }}
              >
                <Dropdown.Toggle
                  id={'per_page_toggle'}
                  style={{ padding: '0px 10px', marginRight: '5px' }}
                >
                  {itemsCountPerPage}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={setPerPage}>10</Dropdown.Item>
                  <Dropdown.Item onClick={setPerPage}>25</Dropdown.Item>
                  <Dropdown.Item onClick={setPerPage}>30</Dropdown.Item>
                  <Dropdown.Item onClick={setPerPage}>50</Dropdown.Item>
                </Dropdown.Menu>

                <span>Per Page</span>

              </Dropdown>

              <div className={"pagination-container"}>

                <div id={'page_number'}>
                  {repoList.length === 0 ? '' : `Page ${activePage}/${totalPages}`}
                </div>

                <Pagination
                  hideDisabled
                  activePage={activePage}
                  itemsCountPerPage={itemsCountPerPage}
                  totalItemsCount={totalItemsCount}
                  pageRangeDisplayed={pageRangeDisplayed}
                  activeClass={'pagination-active'}
                  activeLinkClass={'pagination-active-link'}
                  itemClass={'pagination-item'}
                  linkClass={'pagination-link'}
                  onChange={handlePagination}
                />
                
              </div>
            </div>
            <br/>
            {repoList.length === 0
            ? noResultsContent()
            : repoList.map((repo) => <RepoCard key={repo.key} repo={repo} />)}
            
            <div className={"repo-list-footer"}>

              <div className={"pagination-container"}>

                <div id={'page_number'}>
                  {repoList.length === 0 ? '' : `Page ${activePage}/${totalPages}`}
                </div>

                <Pagination
                  hideDisabled
                  activePage={activePage}
                  itemsCountPerPage={itemsCountPerPage}
                  totalItemsCount={totalItemsCount}
                  pageRangeDisplayed={pageRangeDisplayed}
                  activeClass={'pagination-active'}
                  activeLinkClass={'pagination-active-link'}
                  itemClass={'pagination-item'}
                  linkClass={'pagination-link'}
                  onChange={handlePagination}
                />
  
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  );
};

ExploreCodePage.propTypes = {
  getSummaryData: PropTypes.func.isRequired,
  summaryData: PropTypes.object.isRequired,
  repoData: PropTypes.object.isRequired,
  getRepoData: PropTypes.func.isRequired,
}

export const mapStateToProps = createStructuredSelector({
  summaryData: selectors.getSummaryData(),
  repoData: selectors.getRepoData(),
});

export const mapDispatchToProps = (dispatch) => ({
  getSummaryData: () => dispatch(actions.getSummaryData()),
  getRepoData: (url) => dispatch(actions.getRepoData(url)),
});

const usingRedux = connect(mapStateToProps, mapDispatchToProps)(ExploreCodePage);

export default usingRedux;
