import {
  all, takeEvery, call, put,
} from 'redux-saga/effects';
import { withAsync, api } from 'state-template';

import * as actions from './actions';
import * as C from './constants';

const { CODECA_API, EXAMPLE_API } = window.config;

export function* getSummaryData() {
  const url = `${CODECA_API}/Github/SummaryData`;
  const data = yield call(api.request, url);
  yield put(actions.getSummaryDataSuccess(data));
}

export function* getRepoData(action) {
  const url = action.payload ? action.payload : `${CODECA_API}/Github`;
  // const url = `${CODECA_API}/Github`;
  const data = yield call(api.request, url);
  yield put(actions.getRepoDataSuccess(data));
}

export function* getExampleData() {
  const url = `${EXAMPLE_API}/todos`;
  const data = yield call(api.request, url);
  yield put(actions.getExampleDataSuccess(data));
}

export function* initApp(){
  yield getSummaryData();
  yield getRepoData();
}

export default function* exampleSaga() {
  yield all([
    takeEvery(C.GET_EXAMPLE_DATA, withAsync(getExampleData)),
    takeEvery(C.GET_SUMMARY_DATA, withAsync(getSummaryData)),
    takeEvery(C.GET_REPO_DATA, withAsync(getRepoData))
  ]);
}
