import { createSelector } from 'reselect';

export const selectDomain = (state) => state.reducer;
export const selectExampleData = (state) => selectDomain(state).exampleData;
export const selectLastFetched = (state) => selectDomain(state).lastFetched;
export const selectSummaryData = (state) => selectDomain(state).summaryData;
export const selectRepoData = (state) => selectDomain(state).repoData;

export const getSummaryData = () => createSelector(
  selectSummaryData,
  (summaryData) => summaryData,
);

export const getRepoData = () => createSelector(
  selectRepoData,
  (repoData) => repoData,
);

export const getExampleData = () => createSelector(
  selectExampleData,
  (exampleData) => exampleData,
);

export const getLastFetched = () => createSelector(
  selectLastFetched,
  (lastFetched) => lastFetched,
);
