import React from 'react';
import PropTypes from 'prop-types';
import StateTemplate, { history } from 'state-template';
import routes from 'routes';
import { connect } from 'react-redux';
import * as actions from 'redux/actions';
import caOS from '../../img/code-logo-1.png'; 

const brandingProps = {
    src: caOS,
    alt: 'California Open Source Portal',
};

const App = ({getSummaryData, getRepoData}) => {

    // call init app
    getSummaryData();
    getRepoData();
    return <StateTemplate brandingLogo={brandingProps} routes={routes} history={history} />
 
};

App.propTypes = {
    getSummaryData: PropTypes.func.isRequired,
    getRepoData: PropTypes.func.isRequired,
}

export const mapDispatchToProps = (dispatch) => ({
    getSummaryData: () => dispatch(actions.getSummaryData()),
    getRepoData: () => dispatch(actions.getRepoData()),
  });

const usingRedux = connect(null, mapDispatchToProps)(App);

export default usingRedux;
