/* eslint-disable camelcase */
import React from 'react';
import { Card, Tooltip } from 'state-template';
import PropTypes from 'prop-types';
import './RepoCard.css';

const RepoCard = ({ repo }) => {
  const {
    key,
    avatar_url,
    svn_url,
    title,
    org_name,
    description,
    updated_at,
    languages,
    score,
    alerts,
    stargazers_count,
    forks,
    subscribers_count,
  } = repo;

  // Score background colors
  const bgColorList = {
    null: '#FF0000', // Red
    Unverified: '#FF0000', // Red
    NoScanning: '#FFA500', // Dark Orange
    Alerts: '#FFFF00', // Yellow
    AllGood: '#00CD00', // Green
  };

  // Score font colors
  const fontColorList = {
    null: '#FFFFFF',
    Unverified: '#FFFFFF',
    NoScanning: '#000000',
    Alerts: '#000000',
    AllGood: '#FFFFFF',
  };

  const tooltipMessages = {
    Unverified:
      `<span>CDT is not a collaborative member of repository.
         <br />
       Please see FAQ page on how to add collaborator
       </span>`,
    NoScanning:
      `<span>CDT is a collaborator, but code scanning is not setup for repository.
         <br />
       See FAQ page on how to setup scanning on your repository
       </span>`,
    Alerts: 
    `<span>Alerts detected:</span>`,
    AllGood: `<span>Repository has been scanned and no alerts reported</span>`,
  };

  // Display score in more readable format
  const scoreStrings = {
    Unverified: 'Unverified',
    NoScanning: 'No Scanning',
    Alerts: 'Alerts',
    AllGood: 'All Good',
  };

  // Build content
  const content = (
    <article key={key} className={'course-item'}>
      <Tooltip effect={'solid'} place={'right'} html />

      <div className={'thumbnail'}>
        <img
          style={{ height: '75px', width: '75px' }}
          src={avatar_url}
          alt={''}
        />
      </div>

      <div className={'header'}>        
          <div className={'title col-md-12'}>
            <a style={{fontFamily: 'consolas'}} href={svn_url} target={'blank'}>
              {title}
            </a>
            <div className={'float-right'}>
              <span className={'text-info ca-gov-icon-eye'} title={'Watchers'}/>{subscribers_count}{' '}
              <span className={'text-info ca-gov-icon-favorite'} title={'Stars'}/>{stargazers_count}{' '}
              <span className={'text-info ca-gov-icon-share'} title={'Forks'}/>{forks}
          </div>
          </div>
      </div>

      <div className={'body'} id={'agency_details'}>
        <div className={'text-muted'}>
          <span className={'font-weight-bold'}>Agency Name:</span>
          {' '}
          {org_name ? <i>{org_name}</i> : <i>No agency name</i>}
        </div>

        <div className={'text-muted'}>
          <span className={'font-weight-bold'}>Description:</span>
          {' '}
          {description ? <i>{description}</i> : <i>No description available</i>}
        </div>
        <hr />
        <div className={'datetime text-muted'}>
          <span className={'font-weight-bold'}>Last updated:</span>
          {` ${new Date(updated_at).toDateString()}, ${new Date(
            updated_at,
          ).toLocaleTimeString()}`}
        </div>
        {' '}
        <div className={'text-muted'}>
          <span className={'font-weight-bold'}>Languages:</span>
          {' '}
          {languages[0] !== "" ? (
            <i>{languages.join(', ')}</i>
          ) : (
            <i>N/A</i>
          )}
        </div>
        {' '}
        <div>
          <span className={'text-muted font-weight-bold'}>Score:</span>
          {' '}
          <span
            className={'score-span'}
            style={{
              background: bgColorList[score],
              color: fontColorList[score],
            }}
            data-tip={
              score === 'Alerts'
                ? `${tooltipMessages[score]} ${alerts.map(
                    (alert, index) => ` <br />${index+1} - ${alert.description}`
                  )}`
                : tooltipMessages[score]
            }
          >
            {score === 'Alerts'
              ? ` ${scoreStrings[score]} (${alerts.length})`
              : scoreStrings[score]}
          </span>
        </div>
      </div>

      <div className={'footer'}>
        <a
          href={svn_url}
          target={'blank'}
          className={'btn btn-sm btn-primary'}
          style={{ fontSize: '1.1rem' }}
          aria-label={`Go to ${svn_url}`}
        >
          View Code
        </a>
      </div>
    </article>
  );

  return <Card>{content}</Card>;
};

RepoCard.propTypes = {
  repo: PropTypes.object.isRequired,
}

export default RepoCard;
