import React from 'react';

import './HomePage.css';
import LargeBanner from '../../components/LargeBanner/LargeBanner';
import ImageBlurb from '../../components/ImageBlurb/ImageBlurb';

import image1 from '../../img/article1.png';
import image2 from '../../img/article2.png';
import image3 from '../../img/article3.png';

const HomePage = () => (    
  <div className={"main-content"}>
    <LargeBanner />

    {/* <div className={"card-row blurbs"}>
      <ImageBlurb route={'/Explore'} icon={'code'} title={'Explore Code'} text={'Browse open source projects to reuse code or contribute to'} />
      <ImageBlurb route={'https://codecaopensource-playbook.readthedocs.io/en/latest/onboarding/'} icon={'computer'} title={'Playbook'} text={'Follow the onboarding process and add your agency as a collaborator'} />
    </div> */}

    <div className={"card-row main-primary"}>
      <div className={"content-container"}>
        <div style={{textAlign: 'center'}}>
          <h2>About CodeCA OpenSource</h2>
          <p>
              CodeCA OpenSource is the state government&apos;s platform for sharing California&apos;s open source software
          </p>
        </div>

        <div style={{display: 'flex', padding: '5px'}}>
          <span className={"ca-gov-icon-certificate-check text-2-5rem "} aria-hidden={"true"} />
          <div className={"p-l-md"}>
            <h3 className={"h4 m-t"}><a href={"/About"}>Objective</a></h3>
            <div className={"width-30 brd-top brd-gray p-b"} />
            <p>To cultivate an authentic, supportive, open, collaborative network of digital government innovators and facilitate California state agency implementation of the Open Source Software (OSS)and Code Reuse policy as outlined in Technology Letter 18-02.</p>
          </div>
        </div>
        <div style={{display: 'flex', padding: '5px'}}>
          <span className={"ca-gov-icon-file-code text-2-5rem "} aria-hidden={"true"} />
          <div className={"p-l-md"}>
            <h3 className={"h4 m-t"}><a href={"/About"}>Mission</a></h3>
            <div className={"width-30 brd-top brd-gray p-b"} />
            <p>CodeCA OpenSource will unite, educate and inspire the California government technology community to build a foundation for digital government services through an open, decentralized approach to code and organizational collaboration.</p>
          </div>
        </div>
      </div>

      <div className={'news-container'}>
        <div style={{textAlign: 'center'}}>
          <h2>Open Source News &amp; Events</h2>
          <p>
              Check out some of the latest open source and industry trends
            <br />
            <i>(Non-governmental websites)</i>
          </p>
        </div>

        <article className={"news-item"} style={{marginLeft: '0px', marginRight: '0px'}}>
          <div className={"thumbnail"}><img src={image1} alt={"article 1"} /></div>
          <div className={"info"}>
            <div className={"headline"}><a href={"https://opensource.com/article/21/1/open-source-news"}>Open source leader in White House, Mapbox gets open source alternative, and more</a></div>
            <div className={"description"}><p>Get the latest news to know in this open source roundup</p></div>
            <div className={"published"}>
                Published:
              <time dateTime={"2014-10-20"}>Jan 28, 2021</time>
            </div>
          </div>
        </article>
        <article className={"news-item"} style={{marginLeft: '0px', marginRight: '0px'}}>
          <div className={"thumbnail"}><img src={image2} alt={"article 2"} /></div>
          <div className={"info"}>
            <div className={"headline"}><a href={"https://opensource.com/article/20/12/open-source-news"}>Looking back at trends from 2020, 10 open source news headlines from the past year</a></div>
            <div className={"description"}><p>Take a look back at the open source news that made headlines in 2020</p></div>
            <div className={"published"}>
                Published:
              <time dateTime={"2014-10-20"}>Dec 26, 2021</time>
            </div>
          </div>
        </article>
        <article className={"news-item"} style={{marginLeft: '0px', marginRight: '0px'}}>
          <div className={"thumbnail"}><img src={image3} alt={"article 3"} /></div>
          <div className={"info"}>
            <div className={"headline"}><a href={"https://opensource.com/article/20/12/predictions-more-industry-trends"}>Kubernetes predictions for 2021, scientists are joining Github, and more industry trends</a></div>
            <div className={"description"}><p>A weekly look at open source community and industry trends</p></div>
            <div className={"published"}>
                Published:
              <time dateTime={"2014-10-20"}>Dec 16, 2020</time>
            </div>
          </div>
        </article>
      </div>

    </div>

  </div>
      
  );

export default HomePage;
