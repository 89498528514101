import HomePage from 'containers/HomePage';
import ExploreCodePage from 'containers/ExploreCodePage';

// order here determines order in navigation menu
export const routesByKey = {
  home: {
    key: 'home',
    name: 'Home',
    path: '/',
    exact: true,
    icon: 'ca-gov-icon-home',
    component: HomePage,
  },
  explore:{
    key: 'explore',
    name: 'Explore Code',
    path: '/Explore',
    exact: true,
    icon: 'ca-gov-icon-code',
    component: ExploreCodePage,
  },
  playbook: {
    key: 'playbook',
    name: 'Playbook',
    href: 'https://codecaopensource-playbook.readthedocs.io/en/latest/onboarding',
    exact: false,
    icon: 'ca-gov-icon-book',
  }
};

export default Object.values(routesByKey);
