import { createAction } from 'redux-actions';

import * as C from './constants';

export const patchBecomeAContributor = createAction(C.PATCH_BECOME_A_CONTRIBUTOR);
export const getRepositories = createAction(C.GET_REPOSITORIES);
export const getExampleData = createAction(C.GET_EXAMPLE_DATA);
export const getExampleDataSuccess = createAction(C.GET_EXAMPLE_DATA_SUCCESS);

export const getSummaryData = createAction(C.GET_SUMMARY_DATA);
export const getSummaryDataSuccess = createAction(C.GET_SUMMARY_DATA_SUCCESS);

export const getRepoData = createAction(C.GET_REPO_DATA);
export const getRepoDataSuccess = createAction(C.GET_REPO_DATA_SUCCESS);