import { handleActions } from 'redux-actions';

import * as C from './constants';

export const initialState = {
  exampleData: [],
  lastFetched: null,
  summaryData: {},
  repoData:{},
};

const getSummaryDataSuccess = (state, action) => ({
    ...state,
    summaryData: action.payload,
  })

const getRepoDataSuccess = (state, action) => ({
  ...state,
  repoData: action.payload,
})

const getExampleDataSuccess = (state, action) => ({
  ...state,
  exampleData: action.payload,
  lastFetched: Date.now(),
});

export default handleActions({
  [C.GET_EXAMPLE_DATA_SUCCESS]: getExampleDataSuccess,
  [C.GET_SUMMARY_DATA_SUCCESS]: getSummaryDataSuccess,
  [C.GET_REPO_DATA_SUCCESS]: getRepoDataSuccess,
}, initialState);
