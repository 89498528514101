export const GET_REPOSITORIES = 'GET_REPOSITORIES';

export const GET_SUMMARY_DATA = 'GET_SUMMARY_DATA';
export const GET_SUMMARY_DATA_SUCCESS = 'GET_SUMMARY_DATA_SUCCESS';

export const GET_REPO_DATA = 'GET_REPO_DATA';
export const GET_REPO_DATA_SUCCESS = 'GET_REPO_DATA_SUCCESS';

export const GET_EXAMPLE_DATA = 'GET_EXAMPLE_DATA';
export const GET_EXAMPLE_DATA_SUCCESS = 'Home/GET_EXAMPLE_DATA_SUCCESS';

export const PATCH_BECOME_A_CONTRIBUTOR = 'PATCH_BECOME_A_CONTRIBUTOR';