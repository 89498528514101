import React from 'react'
import PropTypes from 'prop-types';
import './ImageBlurb.css';

const ImageBlurb = ({route, icon, title, text}) => (
  <div className={"third"}>
    <a href={route} className={"no-underline d-block bg-grey-lightest-hover p-a-md"} title={title}>
      <div className={"text-center"}>
        <div className={`ca-gov-icon-${icon} blurb-icon`} />
        <h3 className={"h4 m-b-sm color-gray-dark blurb-title"}>{title}</h3>
        <p className={"color-gray-dark blurb-text"}>{text}</p>
      </div>
    </a>
  </div>
);

ImageBlurb.propTypes = {
  route: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  title: PropTypes.string,
  text: PropTypes.string,
}

ImageBlurb.defaultProps = {
  title: null,
  text: null,
}

export default ImageBlurb;